import { Link } from "gatsby";
import React, { Component } from "react";
import Img from "gatsby-image";
import Moment from 'react-moment';

import "./article.css";

class Article extends Component {
	constructor(props) {
		super(props);
        this.state = {
        };
    }
    
	render() {
		return (
			<article className="article" key={this.props.id}>
                <section className="article__top">
                    <h1 className="article__headline" dangerouslySetInnerHTML={{__html: this.props.title}}></h1>
                    <h2 className="article__subheadline" dangerouslySetInnerHTML={{__html: this.props.subheadline}}></h2>
                    <div className="article__meta">
                        <div className="article__author">
                            { this.props.author && this.props.author.acf.avatar_image ?  <Img className="article__author__image" sizes={this.props.author.acf.avatar_image.localFile.childImageSharp.sizes } /> : '' }
                            <span className="article__author__name">{this.props.author.name}</span>
                            <Moment className="article__date" format="MMMM D, YYYY">{this.props.date}</Moment>
                        </div>
                        <div className="article__categories">
                            <Link to={"/" + this.props.parentCategory.slug + "/" + this.props.subCategory.slug} className="article__categories__link">{this.props.subCategory.name}</Link>, <Link to={"/" + this.props.parentCategory.slug} className="article__categories__link">{this.props.parentCategory.name}</Link>
                        </div>
                    </div>
                </section>
            
                { this.props.media ?  <Img className="article__image" alt={this.props.media.alt_text} sizes={this.props.media.localFile.childImageSharp.sizes} /> : '' }
                { this.props.content ?  <div className="article__content" dangerouslySetInnerHTML={{__html: this.props.content}}></div> : '' }
            </article>
		)
	}
}

export default Article;