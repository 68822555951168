import React, { Component } from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo';
import Layout from "../components/layout";
import Article from "../components/article";

class PostTemplate extends Component {
  render() {
    const currentPost = this.props.data.wordpressPost;

    let parentCat = null;
    let subCat = null;

    currentPost.categories.forEach(cat => {
        if( cat && cat.parent_element ){
            parentCat = cat.parent_element;
            subCat = {
                slug: cat.slug,
                name: cat.name,
                wordpress_id: cat.wordpress_id
            };
        } else {}
    });

    return (
        <Layout data={this.props.data} category={parentCat} subCategory={subCat}>
		    <SEO title={currentPost.title.replace("&#8230;", "...")} description={currentPost.acf.sub_headline} image={currentPost.featured_media.localFile.childImageSharp.fixed.src} />
            <Article id={currentPost.id} link={currentPost.link} title={currentPost.title} media={currentPost.featured_media} excerpt={currentPost.excerpt} subheadline={currentPost.acf.sub_headline} author={currentPost.author} date={currentPost.date} parentCategory={parentCat} subCategory={subCat} content={currentPost.content} />
		</Layout>
    )
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
        acf {
            sub_headline
        }
        author {
            name
            acf {
                avatar_image {
                    localFile {
                        childImageSharp {
                            sizes(maxWidth: 100 ) {
                                ...GatsbyImageSharpSizes
                            }
                            fluid(maxWidth: 100, maxHeight: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        categories { 
            name
            slug
            id
            wordpress_parent
            wordpress_id
            parent_element {
              wordpress_id
              name
              slug
            }
        }
        content
        date
        excerpt
        featured_media {
            localFile {
                childImageSharp {
                    sizes(maxWidth: 2064 ) {
                      ...GatsbyImageSharpSizes
                    }
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 1200) {
                        src
                    }
                }
            }
        }
        id
        link
        title
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
    primaryCategories: allWordpressCategory(filter: {wordpress_parent: {eq: 0}, wordpress_id: {ne: 1}}, sort: {fields: name}) {
        edges {
            node {
                count
                id
                slug
                name
                wordpress_parent
                wordpress_id
                parent_element {
                    slug
                    name
                    wordpress_id
                }
            }
        }
    }
    secondaryCategories: allWordpressCategory(filter: {wordpress_parent: {ne: 0}}, sort: {fields: name}) {
        edges {
            node {
                count
                id
                slug
                name
                wordpress_parent
                wordpress_id
                parent_element {
                    wordpress_id
                    slug
                    name 
                }
            }
        }
    }
  }
`;